import React from "react";
import { Form, Col, Row, Button, Card, CardGroup, Container } from "react-bootstrap";
import { FaLocationArrow } from "react-icons/fa";
import { AiFillMail } from "react-icons/ai";
import { MdCall } from "react-icons/md";
import MetaTags from "react-meta-tags";

function Contact() {
  // this.state = {
  //   fname: "", 
  //   name: "", 
  //   email: "", 
  //   message: "", 
  //   mailSent: false,
  //   error: null
  // };

  // this.onFormSubmitSuccess = (e) => {
  //   e.preventDefault();
  //   console.log(this.state);

  //   axios({
  //       method: 'post',
  //       url: 'http://localhost/contact/',
  //       headers: { 'content-type': 'application/json' },
  //       data: this.state
  //   })
  //       .then(result => {
  //           this.setState({
  //               mailSent: result.data.sent
  //           })
  //       })
  //       .catch(error => this.setState({ error: error.message }));
  // }
  return (
    <div>
      <MetaTags>
        <meta charSet="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
        <title>Best IT training Institute | Marketing Agency</title>
        <meta name="keywords" content="it training institute, software development company in india, marketing agency, BMarketKing"/>
        <meta name="description" content="BMarketKing is the best IT training institue with Share Market Classes. Also a Marketing agency with a glimpse of best software development company in india."/>
      <link rel="canonical" href="https://bmarketking.in/contact-us"/>
    </MetaTags>
      <div className="container-fluid text-center contact-us-banner">
        <h1 className="main-title" ><b>Contact us</b></h1>
        <h6 className="sub-title">
          Do you have any questions? Please do not hesitate to contact us
          directly. Our team will come back to you within a matter of hours to
          help you.
        </h6>
      </div>

      <Container className="mt-5">
        <CardGroup>
          <Card>
            <Card.Header>
              <div className="card-icon">
                <FaLocationArrow style={{"color": "rgb(75, 141, 241)", "fontSize": "1em"}} />
              </div>
              <Card.Title className="service-title">Address</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                Shree Sai Niketan Apt
                Room No 101 Besides UCO bank,
                Bhau Saheb Dandekar Marg,
                Palghar West 401404.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <div className="card-icon">
                <AiFillMail style={{"color": "rgb(75, 141, 241)", "fontSize": "1em"}} />
              </div>
              <Card.Title className="service-title">Email Us</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <a className='text-reset' href = "mailto: piyush@bmarketking.in">piyush@bmarketking.in</a>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <div className="card-icon">
                <MdCall style={{"color": "rgb(75, 141, 241)", "fontSize": "1em"}} />
              </div>
              <Card.Title className="service-title">Call Us</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
              <a className='text-reset' href="tel:9307521084">+ 91 9307521084</a>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </Container>

      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="mt-4"
              dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d120205.92396042723!2d72.69791975820311!3d19.69409060000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be71d5d71d860a9%3A0x8471aeab61f4323!2sBMarketKing!5e0!3m2!1sen!2sin!4v1649066430345!5m2!1sen!2sin" class="map w-100" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>` }}
              />
          </div>
          <div className="col-md-6 col-sm-12">
            <Form>
              <Form.Group as={Row} className="mb-6" controlId="name">
                <Col lg="6"  className="mt-4 ">
                  {/* <Form.Control type="name" placeholder="Name" value={this.state.fname}
                        onChange={e => this.setState({ fname: e.target.value })} /> */}
                </Col>
                <Col lg="6"  className="mt-4">
                  <Form.Control type="email" placeholder="Email" />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3 mt-4" controlId="comment">
                <Col lg="12">
                  <Form.Control as="textarea" rows={9} placeholder="Message" />
                </Col>
              </Form.Group>

              <Form.Group>
                <Col sm="2">
                <Form.Control type="submit" className="btn btn-primary"/>
                </Col>
              </Form.Group>
            </Form>
          </div>

          {/* <div className="col-md-6">
          <img className="border-card" src={Card} alt="card" />
          </div> */}
        </div>
      </div>

      <div className="small-banner mt-5">
        <div className="container-fluid p-5">
          <h2>
            What we Offer 
          </h2>
          <div className="row">
            <div className="col-md-9 mt-2">
              <h4> 
                Our Expertise & Services with knowledgeable
                courses
              </h4>
            </div>
            <div className="col-md-3 mt-2">
              <Button
                href="tel:9307521084"
                variant="default"
                style={{ color: "white", border: "solid" }}
              >
                Schedule a Call with us
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
