import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import courses from "../../data/courses.js";
import { MetaTags } from "react-meta-tags";
import { Container, Card } from "react-bootstrap";
import img from "../../assets/images/underconstruction.svg";


function SingleCourses() {
  const [course, setCourse] = useState();
  let { id } = useParams();

  useEffect(() => {
    let filteredCourse = courses.find((item) => item.id === id);
    setCourse(filteredCourse);
  }, [id]);
  if (course) {
    return (
      <div>
        <MetaTags>
          <meta charSet="UTF-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>{course.title}</title>
          {course.metaTags.map((tag,index) => {
            return <meta name={tag.name} key={index} content={tag.content} />;
          })}
          <link rel="canonical" href={"https://bmarketking.in/courses/" + id} />
        </MetaTags>
        <div className={"container-fluid text-center " + course.className}>
          <div className="row">
          <h1 className="main-title">
            <b>{course.heading}</b>
          </h1>
          <h4 className="sub-main-title">{course.subHeading}</h4>
          <p
            className="sub-title"
            dangerouslySetInnerHTML={{
              __html: course.courseInformation.split(".").join(""),
            }}
          ></p>
          </div>
        </div>
        <div className="container custom-card-container">
          {course.cards.map((card,index) => {
            return (
              <Card key={index} className="text-center rounded dinamic-card">
                <Card.Body className="py-5 ">
                  <Card.Title className="fs-5 service-title">{card.title}</Card.Title>
                  <Card.Text className="py-5 ">{card.info}</Card.Text>
                </Card.Body>
                <Card.Footer>
                  <a href="tel:9307521084" className="btn btn-primary">
                    Enroll Now
                  </a>
                </Card.Footer>
              </Card>
            );
          })}
          {!course.cards.length && (
            <Container className="text-center">
            <img src={img} className="img-fluid w-50" alt="" />
            <h1 className="display-1">Coming Soon</h1>
            <hr />
            <a className="btn btn-primary rounded-pill me-4 mt-4" href="/services">
              Check our available services and courses
            </a>
            <a
              className="btn btn-primary rounded-pill me-4 mt-4"
              href="tel:9307521084"
            >
              Schedule a call{" "}
            </a>
            <a className="btn btn-primary rounded-pill me-4 mt-4" href="/career">
              Looking for Teammates in Palghar
            </a>
          </Container>
          )}
{id === "stock-market-course"? <p className="text-danger">
            Disclaimer:- Equity investment and stock trading are subjected to
            100% Market risks. Refer to your financial consultant's advice
            before investing and trading in the market. BMarketKing Share/Stock
            Market is only for educational and learning knowledge purposes. We
            have no responsibility for your intended decision & financial
            losses. Keep calculated and always analyzed your cash position and
            risk-bearing capacity before following msg of BMarKetKing postings.
            Stock Market investments are very risky and being part of the coming
            lecture session, you agree that you understand the market risks
            involved. Profit and losses are part of the share market. Kindly
            understand and act wisely. BMarketKing does not provide any
            tips/recommendations. All discussions are only for education and
            learning purposes. Do consult your financial advisor before taking
            trades or investment decisions.
          </p>:""}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default SingleCourses;
