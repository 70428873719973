import React from 'react';
import logo from "../../assets/images/bmarketkingorg.png";
import { Navbar } from 'react-bootstrap';
import { MDBFooter } from 'mdb-react-ui-kit';
import { BsFacebook, BsInstagram, BsLinkedin, BsFillHouseDoorFill, BsEnvelopeFill, BsFillTelephoneFill } from "react-icons/bs";


export default function Footer() {
  return (
    <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href='https://www.facebook.com/bmarketking/' className='me-4 text-reset'>
            <BsFacebook />
          </a>
          <a href='https://www.instagram.com/bmarketking.in/' className='me-4 text-reset'>
             <BsInstagram />
          </a>
          <a href='https://www.linkedin.com/company/bmarketking' className='me-4 text-reset'>
             <BsLinkedin />
          </a>
          {/* <a href='' className='me-4 text-reset'>
            <i className='fab fa-github'></i>
          </a> */}
        </div>
      </section>

      <section className=''>
        <div className='container text-center text-md-start mt-5'>
          <div className='row mt-3'>
            <div className='col-md-3 col-lg-4 col-xl-3 mb-4'>
              <div className='mb-4'>
            <Navbar.Brand href="/"><img src={logo} className="nav-logo" alt="logo"/></Navbar.Brand>
              </div>
              {/* <h6 className='text-uppercase fw-bold mb-4'>
                <i className='fas fa-gem me-3'></i>BMarketKing
              </h6> */}
              <p>
              Leading Digital Marketing Company in Palghar Which empowers your Business to become the Superior one.
              </p>
            </div>

            <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <BsFillHouseDoorFill /> Shree Sai Niketan Apt <br /> Room No 101 Besides UCO bank,<br /> Bhau Saheb Dandekar Marg,<br /> Palghar West 401404.
              </p>
              <p>
                <BsEnvelopeFill /> <a className='text-reset' href = "mailto: piyush@bmarketking.in">piyush@bmarketking.in</a>
              </p>
              <p>
                <BsFillTelephoneFill /> <a className='text-reset' href="tel:9307521084">+ 91 9307521084</a>
              </p>
            </div>


            <div className='col-md-3 col-lg-2 col-xl-2 mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
                <a href='/services/digital-marketing' className='text-reset'>
                Digital Marketing
                </a>
              </p>
              <p>
                <a href='/services/web-design' className='text-reset'>
                  Web Design
                </a>
              </p>
              <p>
                <a href='/services/web-development' className='text-reset'>
                  Web Development
                </a>
              </p>
              <p>
                <a href='/services/video-graphic' className='text-reset'>
                  Video Graphic
                </a>
              </p>
              <p>
                <a href='/services/content-marketing' className='text-reset'>
                  Content Marketing
                </a>
              </p>
            </div>

          </div>
        </div>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2022 Copyright all right reserved : &nbsp;
        <a className='text-reset fw-bold text-decoration-none' href='/'>
        BMarketKing
        </a>
      </div>
    </MDBFooter>
  );
}