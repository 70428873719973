import React from 'react';
import { MetaTags } from "react-meta-tags";
import headinglinedec from "../../assets/images/heading-line-dec.png";
import YoutubeEmbed from "../helper/YoutubeEmbed";
import Button from "react-bootstrap/Button";
import { IoIosStar } from "react-icons/io";
import { GrGoogle } from "react-icons/gr";
import { MdGroups } from "react-icons/md";
import { IoTodaySharp } from "react-icons/io5";
import { IoIosPricetag } from "react-icons/io";
import aboutSpeaker from "../../assets/images/about-speaker.jpg";
import { Card, CardGroup, Container } from "react-bootstrap";
import { Collapse } from 'antd';

function Digitalmarketing(){
  return (
    <div>
      <MetaTags>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>Best Digital Marketing Course in Palghar | BMarketKing</title>
        <meta
          name="keywords"
          content="digital marketing course, best digital marketing course, digital marketing courses in palghar, digital marketing classes in palghar, BMarketKing"
        />
        <meta
          name="description"
          content="Searching for the best digital marketing course ? We are one call away from you. Both online & classroom training is given to improve your skills."
        />
        <link rel="canonical" href={"https://bmarketking.in/courses/digital-marketing-course"} />
      </MetaTags>

      <div className="container-fluid text-center digital-marketing-banner">
        <div className="row">
        <h1 className="main-title">
          <b>360° Digital marketing course</b>
        </h1>
        <h4 className="sub-main-title">Empower your future discover the best digital marketing course for success </h4>
        <p className="sub-title">BMarketKing provides Training of Digital Marketing Course to Job Seekers, Freelancers, Businessman, College Students and to the Future Entrepreneurs to drive their Startup in a smooth way.</p>
        </div>
      </div>

      <div id="about" className="about-us section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 align-self-center">
              <div className="section-heading">
                <h4>
                  About Course
                </h4>
                <img src={headinglinedec} className="img-fluid" alt="" />
                <p>Looking for the best digital marketing course ? Look no further! At Bmarketking Our comprehensive digital marketing course is designed to equip you with the knowledge & skills needed to excel in today's digital world. Whether you are a beginner or already have some experience, our digital marketing course covers all aspects including search engine optimization (SEO), social media marketing, email marketing, content marketing, and many more.By the end of the course, you will have a solid understanding of digital marketing strategies and be ready to create successful campaigns that drive results.</p>
                <p>Join our digital marketing course today and take your skills & strategies to the next level !</p>
                <p>At Bmarketking more focus is given to practical skills to get in depth knowledge of the topic to the learners so it becomes very easy to understand the course from its roots, that's why our competitors also says that this is best digital marketing courses in palghar</p>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="right-image">
                <YoutubeEmbed embedId="Sd-UEnqHQeU" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Container className="digital-marketing-collapse">
        <div className="row">
          <div className="section-heading">
            <h4>
              Why this course ? 
            </h4>
            <img src={headinglinedec} className="img-fluid" alt="" />
            <p>The digital marketing course event is a 4 months practical course where participants learn about various digital marketing strategies such as SEO, social media marketing, content creation, email marketing, and video editing & graphics (canva). Industry experts share insights, and networking opportunities foster collaboration. Its goal is to equip attendees with the skills needed to excel in digital marketing.</p>
            <li>As we said this is the best digital marketing course because it focuses on practical learning and enhancement.</li>
            <li>With digital marketing you can reach a global audience, expanding your business reach</li>
            <li>Digital marketing course can open new career opportunities in various fields and help you advance in your professional life</li>
            <li>There are no specific education requirements in this course. learners of any field can grow in digital marketing </li>
            <li>Digital marketing is a rapidly growing industry with a multitude of job opportunities therefore it is important to learn digital marketing course </li>
          </div>
        </div>
        <br></br>
        <Collapse
          size="small"
          items={[
            {
              key: 't1',
              label: 'Fundamentals of Digital Marketing',
              children: <ul><li>What is Digital Marketing</li>
              <li>Fundamentals of Digital Marketing</li>
              <li>Benefits of Digital Marketing</li>
              <li>Scope Of Digital Marketing</li>
              <li>Requirements in Digital Marketing</li>
              <li>Types Of Marketing</li>
              <li>Job Opportunities In Digital Marketing</li></ul>,
            },
          ]}
          defaultActiveKey={['t1']}
        />
        <Collapse
          size="small"
          items={[
            {
              key: 't2',
              label: 'SEO',
              children: <ul><li>Introduction to SEO</li>
              <li>On-Page SEO & Off-Page SEO & Technical SEO</li>
              <li>Ranking Algorithms & Writing a perfect blog for SEO</li>
              <li>How to Optimise a Perfect -Service Page</li>
              <li>Keyword Research using Different Tools</li> 
              <li>Google Search Console Explained GA4 for SEO</li>
              <li>Understanding Local SEO, What is Google My Business</li>
              <li>Profile and how it works?</li></ul>,
            },
          ]}
        />
        <Collapse
          size="small"
          items={[
            {
              key: 't3',
              label: 'Google ads',
              children: <ul><li>Introduction to Google Ads & How do Google ads work</li>
              <li>Diff. Types Of Objectives in Google Ads & Diff.Types Of Google Ads Format</li>
              <li>How to Choose the Right Objective</li>
              <li>Understanding Importance of Landing Page</li>
              <li>In-depth Search Ads Creation and Optimization</li>
              <li>Creation & Optimization Report</li>
              <li>In-Depth Display Ads & Everything About Remarketing Concepts</li>
              <li>Types Of Remarketing Audience & How to Create a Remarketing Audience</li>
              <li>What is Conversion & How to Create Conversion</li>
              <li>YouTube Ads, Video Ads, App Install Ads, Call Only Ads</li>
              <li>Important Settings in Google Ads</li>
              <li>Shopping Ads & Setting Performance Max Ads</li></ul>,
            },
          ]}
        />
        <Collapse
          size="small"
          items={[
            {
              key: 't4',
              label: 'Social media ads',
              children: <ul><li>What is Facebook Ads & How it Works?</li>
              <li>Difference B / W Personal Facebook ads v/s Facebook Business Manager</li> 
              <li>Different Types of Facebook Ads Objectives / Ad Format & How to Choose the Right Objective for Ads</li>
              <li>Complete Audience,Targeting Option, Complete Placement Option in Facebook Ads & How to write highly convertible Ad copy</li>
              <li>What is the Buyer's Persona , What is Facebook Pixel, How to set up Facebook Pixel</li>
              <li>What is Remarketing & How to Create Remarketing Audiences?</li>
              <li>How to Setup Conversion, How to set advanced Lead Generation ads on Facebook</li>
              <li>How to Run Catalogue Shopping Ads on Facebook</li>
              <li>What is Dynamic Ads & How to Run Remarketing Audiences?</li>
              <li>What is Conversion & How to Setup Conversion</li>
              <li>How to set advanced Lead Generation ads on Facebook</li>
              <li>What is Dynamic Ads & How to run all types of Ads on Instagram</li></ul>,
            },
          ]}
        />
        <Collapse
          size="small"
          items={[
            {
              key: 't5',
              label: 'Google analytics',
              children: <ul><li>Introduction Of Google Analytics GA4</li>
              <li>Setting Up Property In Google Analytics GA4</li>
              <li>Dashboard Overview, Real-Time Report, Acquisition Report, Engagement Report, Retention Report,Tech Report</li>
              <li>What is an Event & How to Create Events, How To Create Conversion</li>
              <li>How to Create Audiences Using GA</li>
              <li>How to import Audiences from GA to Google Ads</li>
              <li>How to Create Report For Clients by GA4 analytics</li></ul>,
            },
          ]}
        />
        <Collapse
          size="small"
          items={[
            {
              key: 't6',
              label: 'Google tag manager',
              children: <ul><li>What is GTM and how to Setup Google Tag Manager</li>
              <li>Why are GA  & GTM connected in the website</li>
              <li>Components in GTM, What is Tag ? Trigger & Variables</li>
              <li>Setting First tracking, Video tracking, image tracking, Link Tracking, Outbound link tracking, Button tracking, Scroll tracking</li>
              <li>Facebook Pixel install by GTM</li>
              <li>Other Tags which GTM can install</li></ul>,
            },
          ]}
        />
        <Collapse
          size="small"
          items={[
            {
              key: 't7',
              label: 'Other extra',
              children: <ul><li>Introduction & Setup of LinkedIn, Quora And Other Ads</li>
              <li>Introduction to email marketing and mailchimp</li> 
              <li>How to Prepare for Interview / Mock Interviews after each and every Module</li>
              <li>What is WordPress?</li>
              <li>Design Professional Blog</li>
              <li>Design Professional Website on wordpress</li></ul>,
            },
          ]}
        />
      </Container>

      <div className="small-banner mt-5">
        <div className="container-fluid p-5">
          <h2>
            What we Offer 
          </h2>
          <div className="row">
            <div className="col-md-9 mt-2">
              <h4> 
                Our Expertise & Services with knowledgeable
                courses
              </h4>
            </div>
            <div className="col-md-3 mt-2">
              <Button
                href="tel:9307521084"
                variant="default"
                style={{ color: "white", border: "solid" }}
              >
                Schedule a Call with us
              </Button>
            </div>
          </div>
        </div>
      </div>
      
      <Container className="services-container">
        <div className="row">
            <div className="section-heading">
              <h4>
                Beneficial for 
              </h4>
              <img src={headinglinedec} className="img-fluid" alt="" />
              <p>A digital marketing course can be beneficial for various individuals and professionals, including:</p>
            </div>
            <CardGroup>
              <Card className="text-center home-cards-service rounded">
                <Card.Body className="py-5">
                  <Card.Title className="service-title">
                    Marketing professionals{" "}
                  </Card.Title>
                  <img
                    src={headinglinedec}
                    className="img-fluid text-center"
                    alt=""
                  />
                  <Card.Text className="py-5">
                    A digital marketing course can help marketing professionals enhance their skills and stay updated with the latest trends and techniques in the digital marketing industry.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="text-center home-cards-service rounded">
                <Card.Body className="py-5">
                  <Card.Title className="service-title">
                    Business owners{" "}
                  </Card.Title>
                  <img
                    src={headinglinedec}
                    className="img-fluid text-center"
                    alt=""
                  />
                  <Card.Text className="py-5">
                    Digital marketing courses can provide business owners with the knowledge and skills to effectively promote their products or services online, reach a wider audience, and increase their online visibility.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="text-center home-cards-service rounded">
                <Card.Body className="py-5">
                  <Card.Title className="service-title">
                    Entrepreneurs{" "}
                  </Card.Title>
                  <img
                    src={headinglinedec}
                    className="img-fluid text-center"
                    alt=""
                  />
                  <Card.Text className="py-5">
                    For entrepreneurs starting their own businesses, our best digital marketing course can provide them with the necessary skills to create and implement effective marketing strategies on a limited budget.
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardGroup>
            <CardGroup>
              <Card className="text-center home-cards-service rounded">
                  <Card.Body className="py-5">
                    <Card.Title className="service-title">
                      Job seekers{" "}
                    </Card.Title>
                    <img
                      src={headinglinedec}
                      className="img-fluid text-center"
                      alt=""
                    />
                    <Card.Text className="py-5">
                      Digital marketing skills are highly in demand in today's job market. Taking our best digital marketing course can improve job prospects and make candidates more competitive in the job market.
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card className="text-center home-cards-service rounded">
                  <Card.Body className="py-5">
                    <Card.Title className="service-title">
                      Freelancers:{" "}
                    </Card.Title>
                    <img
                      src={headinglinedec}
                      className="img-fluid text-center"
                      alt=""
                    />
                    <Card.Text className="py-5">
                      Freelancers, such as content writers, social media managers, and SEO specialists, can benefit from our best digital marketing course to expand their skill set and offer a wider range of services to clients.
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card className="text-center home-cards-service rounded">
                  <Card.Body className="py-5">
                    <Card.Title className="service-title">
                      Students and graduates{" "}
                    </Card.Title>
                    <img
                      src={headinglinedec}
                      className="img-fluid text-center"
                      alt=""
                    />
                    <Card.Text className="py-5">
                      we provide digital marketing classes in Palghar specially for Students and recent graduates looking to enter the marketing field can gain a competitive edge by acquiring digital marketing skills through a course. 
                    </Card.Text>
                  </Card.Body>
                </Card>
              </CardGroup>
          </div>
      </Container>

      <div class = "container review-sec">
        <div class = "row">
          <center>
            <h5>Google Reviews</h5>
            <span class = "pr-1">4.9</span>
            <span style={{ "font-size": 25 }}>
              <IoIosStar style={{ color: 'orange' }}/>
              <IoIosStar style={{ color: 'orange' }}/>
              <IoIosStar style={{ color: 'orange' }}/>
              <IoIosStar style={{ color: 'orange' }}/>
              <IoIosStar style={{ color: 'orange' }}/>
            </span>
            <div style={{ width: '400px' }} className="box-item">
              <h4>
                <a href = "https://www.google.com/search?q=Bmarketking&rlz=1C1RXQR_enIN1032IN1032&oq=Bmarketking& gs_lcrp=EgZjaHJvbWUyDggAEEUYJxg5GIAEGIoFMgYIARBFGDsyBggCEEUYPTIGCAMQRRg8MgYIBBBFGDwyBggFEEUYPDIGCAYQRRhBqAIAsAIA&sourceid=chrome&ie=UTF-8#lrd=0x3be71d5d71d860a9:0x8471aeab61f4323,1,,,," className="btn btn-primary rounded-pill w-100">
                <GrGoogle class= "pr-1" style={{ "margin-bottom" : '2px',"font-size": "22px" }}/><b>Check On Google</b>
                </a>
              </h4>
            </div>
          </center>
        </div>
      </div>

      <div class = "container course-info-sec">
        <div class = "row">
          <div class = "col-md-4">
            <center>
              <h6>Batch</h6>
              <div className="card-icon">
                <MdGroups style={{ "color": "rgb(75, 141, 241)","font-size": 50, height: "50px" }}/>
              </div>
              <h6>10 Students / Batch</h6>
            </center>
          </div>
          <div class = "col-md-4">
            <center>
              <h6>Duration</h6>
              <div className="card-icon">
                <IoTodaySharp style={{ "color": "rgb(75, 141, 241)","font-size": 40, height: "50px" }}/>
              </div>
              <h6>4 Months</h6>
            </center>
          </div>
          <div class = "col-md-4">
            <center>
              <h6>Price</h6>
              <div className="card-icon">
                <IoIosPricetag style={{ "color": "rgb(75, 141, 241)","font-size": 40, height: "50px" }}/>
              </div>
              <h6>₹ 25,000</h6>
            </center>
          </div>
        </div>
      </div>

      <div id="about" className="about-us section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 align-self-center">
                <h4>
                  About speaker
                </h4>
                <img src={headinglinedec} className="img-fluid" alt="" />
              <div>
                <img src={aboutSpeaker} className="img-fluid" style={{ height: "360px",width: "600px" }} alt="" />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5 mt-5">
              <div class = "mt-5" className="section-heading">
                <p>Introducing our esteemed speaker, Piyush Baswat, a senior Marketing Executive at	BMarketing.in. With a wealth of experience and expertise in the field of marketing, Piyush brings a unique perspective to the table. His	dynamic approach and innovative strategies have propelled numerous businesses to new heights of success. As a passionate and	knowledgeable professional, Piyush	consistently stays ahead of the game, keeping up with the latest industry trends and	developments. Join us as Piyush shares his insights, practical tips, and proven techniques that will empower you to excel in the ever-evolving world of digital marketing. Get ready to be inspired and take your marketing efforts to the next level 360° Digital marketing course with <b>Piyush Baswat</b>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Container className="digital-marketing-faq">
        <div className="row">
          <div className="section-heading">
            <h4>
              FAQ 
            </h4>
            <img src={headinglinedec} className="img-fluid" alt="" />
          </div>
        </div>
        <Collapse
          size="large"
          items={[
            {
              key: 'r1',
              label: 'What is digital marketing?',
              children: <p>Digital marketing is the practice of promoting products, services, or brands using digital channels such as websites, social media, search engines, email, and mobile apps. It involves various strategies and tactics to reach and engage with a target audience, increase brand awareness, drive website traffic, generate leads, and ultimately, achieve business goals etc.. we hope our digital marketing course in palghar helps you to achieve your success</p>,
            },
          ]}
          defaultActiveKey={['r1']}
        />
        <Collapse
          size="large"
          items={[
            {
              key: 'r2',
              label: 'How long does a digital marketing course usually take to complete?',
              children: <p>At BMarketking our digital marketing courses in Palghar duration of this course is 4 months. With lots of practical knowledge and guidance. You will learn many different new things that will help you to become successful digital marketer so book your seats now</p>,
            },
          ]}
        />
        <Collapse
          size="large"
          items={[
            {
              key: 'r3',
              label: 'What is the price of this 360° digital marketing course ?',
              children: <p>Our digital marketing classes in palghar are equipped with the best teachers and knowledge need for today's competitive market the pricing of our courses are 25000/-
              You can also pay the fees in instalments
              Book your seats now</p>,
            },
          ]}
        />
        <Collapse
          size="large"
          items={[
            {
              key: 'r4',
              label: 'what i will get from this event ?',
              children: <p>Our digital marketing courses in palghar specially designed to provide you in depth knowledge about digital marketing its tools and strategies. You will learn SEO, SMM, , google ads and you will work on live projects as well as you will get an internship after that we will provide you 100% job assistance (T&C apply ) so book your seats now.</p>,
            },
          ]}
        />
        <Collapse
          size="large"
          items={[
            {
              key: 'r5',
              label: 'Location of this session',
              children: <p>We offer our best in class digital marketing course in Palghar which is near to the station further address , Shree Sai Niketan Apt Room No 101 Besides UCO bank, Bhau Saheb Dandekar Marg, Palghar West 401404. 
              Enrol now</p>,
            },
          ]}
        />
      </Container>
    </div>
  );
}

export default Digitalmarketing;
