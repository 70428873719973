import React from "react";
import { Card, Button, Container } from "react-bootstrap";
import MetaTags from "react-meta-tags";


function Services() {
  return (
    <div className="about-banner">
      <MetaTags>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>Complete Buffet of Digital Marketing & Web design services</title>
        <meta name="keywords" content="digital marketing services, web design services, web development services, social media marketing services, content marketing services"/>
        <meta
          name="description"
          content="BMarketKing provides Digital marketing services, web design, and web development services with the add-on of social media marketing & content marketing service"
        />
        <link rel="canonical" href="https://bmarketking.in/services" />
      </MetaTags>

      <div className="container-fluid text-center service-banner">
        <h1 className="main-title" ><b>Services</b></h1>
        <h4 className="sub-main-title">
          Let's Make together Your Business vision into a successful MileStone
          <br />
          With the help of our Digital Marketing Services
        </h4>
        <p className="sub-title">
          BMarketKing helps the client’s product to rise above all competitors mountain. We make your online business engaging by providing the best Web design Services
        </p>
      </div>

      <Container>
        <div className="flex-both-axis-center">
          <Card className="text-center card-flex-item rounded home-cards-service">
            <Card.Body className="py-5">
              <Card.Title className="service-title"><strong>Digital Marketing Services</strong></Card.Title>
              <Card.Text className="py-5">
                Reach out to your end-user by enabling all key online factors
                like SEO, PPC, online reputation management, Backlinks
                generation, Analytics part
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button variant="primary" href="/services/digital-marketing" >Learn More</Button>
            </Card.Footer>
          </Card>
          <Card className="text-center card-flex-item rounded home-cards-service">
            <Card.Body className="py-5">
              <Card.Title className="service-title"><strong>Web Design Services</strong></Card.Title>
              <Card.Text className="py-5">
                Designing is the secret to attract end users. We turn your
                concept in real by touching the segments such as UX, website &
                graphic design, Logo branding
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button variant="primary" href="/services/web-design" >Learn More</Button>
            </Card.Footer>
          </Card>
          <Card className="text-center card-flex-item rounded home-cards-service">
            <Card.Body className="py-5">
              <Card.Title className="service-title"><strong>Web Development services</strong></Card.Title>
              <Card.Text className="py-5">
                Develop Dynamic websites in PHP, Dot Net, wordpress which are
                mobile-friendly. Quality coding to make business flow smoothly
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button variant="primary" href="/services/web-development" >Learn More</Button>
            </Card.Footer>
          </Card>
          <Card className="text-center card-flex-item rounded home-cards-service">
            <Card.Body className="py-5">
              <Card.Title className="service-title"><strong>Social media marketing services</strong></Card.Title>
              <Card.Text className="py-5">
                Our skills help you to create a relevant & engaging social media
                presence. Make professional business videos for your product
                promotion
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button variant="primary" href="/services/video-graphic" >Learn More</Button>
            </Card.Footer>
          </Card>
          <Card className="text-center card-flex-item rounded home-cards-service">
            <Card.Body className="py-5">
              <Card.Title className="service-title"><strong>Content Marketing services</strong></Card.Title>
              <Card.Text className="py-5">
                Words are powerful so we use them wisely to make sure that it
                reaches the right audience & make a proper impact. Empower
                through website content creation, Blog, newsletter & Emails
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button variant="primary" href="/services/content-marketing" >Learn More</Button>
            </Card.Footer>
          </Card>
        </div>
      </Container>

      <div className="small-banner mt-5">
        <div className="container-fluid p-5">
          <h2>
            What we Offer 
          </h2>
          <div className="row">
            <div className="col-md-9 mt-2">
              <h4> 
                Our Expertise & Services with knowledgeable
                courses
              </h4>
            </div>
            <div className="col-md-3 mt-2">
              <Button
                href="tel:9307521084"
                variant="default"
                style={{ color: "white", border: "solid" }}
              >
                Schedule a Call with us
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
