import React from "react";
import heroModal from "../../assets/images/home-first.png";
import headinglinedec from "../../assets/images/heading-line-dec.png";
import aboutrightdec from "../../assets/images/home-what-we-do.svg";
import { BsFillTelephoneFill, BsFillBookFill } from "react-icons/bs";
import { GiPaperPlane,GiAirplane,GiUfo } from "react-icons/gi";
import Button from "react-bootstrap/Button";
import { Card, CardGroup, Container } from "react-bootstrap";
import MetaTags from "react-meta-tags";

import {
  FaBookReader,
  FaFortAwesome,
  FaPenFancy,
  FaPhoneAlt,
} from "react-icons/fa";
const home = () => {
  return (
    <div>
      <MetaTags>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>Digital Marketing & Web designing company | Stock Market Classes</title>
        <meta
          name="keywords"
          content="digital marketing, web designing company, stock market classes, online marketing company, digital marketing company, BMarketKing"
        />
        <meta
          name="description"
          content="BMarketKing is Digital Marketing & Web Designing Company. specialized in Stock market classes and many more. Online marketing company in Palghar"
        />
        <link rel="canonical" href="https://bmarketking.in" />
      </MetaTags>
      <div
        className="main-banner wow fadeIn"
        id="top"
        data-wow-duration="1s"
        data-wow-delay="0.5s"
      >
        <div className="container first-section">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 align-self-center col-sm-12 pb-2">
                  <div
                    className="left-content show-up header-text wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <h2>
                          We <strong className="title-color">BMarketKing</strong>
                          {" "}Helps You to Be<br></br>
                          The Marketing King
                        </h2>
                        <p>
                          Leading Digital Marketing Company in Palghar
                          <br /> Which empowers your Business to become the
                          Superior one.
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 d-flex">
                        <div className="m-1">
                          <a
                            href="/services"
                            className="btn btn-light rounded-pill"
                          >
                            <BsFillBookFill className="me-1" /> Our courses &
                            services
                          </a>
                        </div>
                        <div className="m-1">
                          <a
                            href="tel:9307521084"
                            className="btn btn-light rounded-pill"
                          >
                            <BsFillTelephoneFill className="me-1" /> Call On:-
                            9307521084
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <img
                    src={heroModal}
                    className="right-image-img img-fluid rounded pb-3 pt-5"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div className="container-fluid second-section">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div
              className="section-heading  wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <div className="quote-symbols">
              <h3 className="text-center">
                What is a <strong className="secondary-title-color">Failure</strong> ?
              </h3>
              <h4 className="text-center">
                It is a Drug that Makes us Addicted towards <strong className="secondary-title-color">SUCCESS</strong>
              </h4>
              </div>
              <br></br>
              <p className="text-center">
                Namaste 🙏 We are the Online Marketing Company that Connects
                with the Client's Product to shape their Business and make them
                a BRAND. For more information{" "}
                <a className="link" href="/contact">
                  contact us.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="about" className="about-us section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 align-self-center">
              <div className="section-heading">
                <h4>
                  About What We Do &amp; Who We Are
                </h4>
                <img src={headinglinedec} className="img-fluid" alt="" />
                <p>
                  We are a digital services providing company as well as we take
                  Online & Offline Stock Market Classes. Explore our Marketing
                  Buffet.
                </p>
              </div>
              <br></br>
              <div className="row">
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4>
                      <a href = "/services/web-design" className="btn btn-primary rounded-pill w-100">
                        Web Designing Company
                      </a>
                    </h4>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4>
                      <a href = "/services/digital-marketing" className="btn btn-primary rounded-pill w-100">
                        Digital Marketing Company
                      </a>
                    </h4>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4>
                      <a href = "/services/video-graphic" className="btn btn-primary rounded-pill w-100">
                        Business Video Production
                      </a>
                    </h4>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4>
                      <a href = "/courses/stock-market-course" className="btn btn-primary rounded-pill w-100">
                        Stock Market Classes
                      </a>
                    </h4>
                  </div>
                </div>
                <div className="col-lg-12"></div>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="right-image">
                <img src={aboutrightdec} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="small-banner mt-5">
        <div className="container-fluid p-5">
          <h2>
            What we Offer 
          </h2>
          <div className="row">
            <div className="col-md-9 mt-2">
              <h4> 
                Our Expertise & Services with knowledgeable
                courses
              </h4>
            </div>
            <div className="col-md-3 mt-2">
              <Button
                href="tel:9307521084"
                variant="default"
                style={{ color: "white", border: "solid" }}
              >
                Schedule a Call with us
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="container fourth-section">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <h3 className="text-center">
              Why do our Competitors call us <strong className="secondary-title-color">Marketing Gunda</strong> ?
            </h3>
            <br></br>
            <p className="text-center">
              Everyone provides Marketing services but we provide High-Quality
              work with creative engagement. Everyday your product is on a path
              to becoming <strong>BRAND</strong>
            </p>
          </div>
        </div>
      </div>
      <Container className="services-container">
        <CardGroup>
          <Card className="text-center home-cards-service rounded">
            <Card.Body className="py-5">
              <div className="card-icon">
                <FaFortAwesome />
              </div>
              <Card.Title className="service-title">
                DIgital Marketing{" "}
              </Card.Title>
              <img
                src={headinglinedec}
                className="img-fluid text-center"
                alt=""
              />
              <Card.Text className="py-5">
                Enable your business on all online platforms with BMarketKing
                Digital marketing agency in Palghar.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button href="/services/digital-marketing" variant="primary">
                Learn More
              </Button>
            </Card.Footer>
          </Card>
          <Card className="text-center home-cards-service rounded">
            <Card.Body className="py-5">
              <div className="card-icon">
                <FaPenFancy />
              </div>
              <Card.Title className="service-title">
                Content Marketing
              </Card.Title>
              <img
                src={headinglinedec}
                className="img-fluid text-center"
                alt=""
              />
              <Card.Text className="py-5">
                Offers creative content creation to your business for
                conversion. unique content writing.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button href="/services/content-marketing" variant="primary">
                Learn More
              </Button>
            </Card.Footer>
          </Card>
          <Card className="text-center home-cards-service rounded">
            <Card.Body className="py-5">
              <div className="card-icon">
                <FaBookReader />
              </div>
              <Card.Title className="service-title">Our Courses</Card.Title>
              <img
                src={headinglinedec}
                className="img-fluid text-center"
                alt=""
              />
              <Card.Text className="py-5">
                Offers a wide range of Digital Marketing Institute, stock market
                courses online & Offline.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button href="/courses" variant="primary">
                Learn More
              </Button>
            </Card.Footer>
          </Card>
          <Card className="text-center home-cards-service rounded">
            <Card.Body className="py-5">
              <div className="card-icon">
                <FaPhoneAlt />
              </div>
              <Card.Title className="service-title">Contact us</Card.Title>
              <img
                src={headinglinedec}
                className="img-fluid text-center"
                alt=""
              />
              <Card.Text className="py-5">
                Get Ready To Create Digital Milestones With Us.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button href="/contact-us" variant="primary">
                Contact Us
              </Button>
            </Card.Footer>
          </Card>
        </CardGroup>
      </Container>

      <div className="container fifth-section">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <h3 className="text-center">In-Offer Available Premium Courses</h3>
            <br></br>
            <p className="text-center">
              Enroll to become Bull or a Bear with our stock market classes
            </p>
          </div>
        </div>
      </div>
      <Container className="services-container">
        <CardGroup>
          <Card className="text-center home-cards-courses rounded">
            <Card.Body className="py-5">
              <div className="card-icon">
                <GiPaperPlane style={{"color": "rgb(75, 141, 241)", "fontSize": "3em"}} />
              </div>
              <Card.Title className="display-5">Basic</Card.Title>
              <img
                src={headinglinedec}
                className="img-fluid text-center"
                alt=""
              />
              <Card.Text className="py-5">
                Share market basics for beginners to learn the starting phases
                of the investing market. Understanding different types of
                platforms, tools, and indicators.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button href="tel:9307521084" variant="primary">
                Enroll Now
              </Button>
            </Card.Footer>
          </Card>
          <Card className="text-center home-cards-courses rounded">
            <Card.Body className="py-5">
            <div className="card-icon">
                <GiAirplane style={{"color": "rgb(75, 141, 241)", "fontSize": "3em"}} />
              </div>
              <Card.Title className="display-5">Advance</Card.Title>
              <img
                src={headinglinedec}
                className="img-fluid text-center"
                alt=""
              />
              <Card.Text className="py-5">
                In this Plan you will Explore more about how to become a Trader
                with a Proper Mindset, Risk Management techniques, and much
                more.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button href="tel:9307521084" variant="primary">
                Enroll Now
              </Button>
            </Card.Footer>
          </Card>
          <Card className="text-center home-cards-courses rounded">
            <Card.Body className="py-5">
            <div className="card-icon">
                <GiUfo style={{"color": "rgb(75, 141, 241)", "fontSize": "3em"}} />
              </div>
              <Card.Title className="display-5">Pro</Card.Title>
              <img
                src={headinglinedec}
                className="img-fluid text-center"
                alt=""
              />
              <Card.Text className="py-5">
                Trade or Invest like a Pro. By making a robust base of basic and
                Advance knowledge. Learn to make Strategies to bring out the
                best outcome. The market is like an Ocean Lets learn to Swim in
                it.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button href="tel:9307521084" variant="primary">
                Enroll Now
              </Button>
            </Card.Footer>
          </Card>
        </CardGroup>
      </Container>
    </div>
  );
};

export default home;
