import React from "react";
import { Card, Button, Container, ListGroup, ListGroupItem } from "react-bootstrap";
// import aboutrightdec from "../../assets/images/about-right-dec.png";
// import { MdLibraryBooks } from "react-icons/md";
import MetaTags from "react-meta-tags";


function Career() {
  return (
    <div className="about-banner">
      
<MetaTags>
	<meta charSet="UTF-8"/>
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
    <title>Jobs at BMarketKing | Palghar</title>
    <meta name="keywords" content="digital marketing jobs, web designing jobs, graphics designer jobs, BMarketKing"/>
    <meta name="description" content="Stop your search. join with BMarketKing if your are looking for Digital Marketing jobs, Web Designing Jobs, Graphics Designer Jobs. get connect with us."/>
	<link rel="canonical" href="https://bmarketking.in/career"/>
</MetaTags>
      <div id="band" className="container-fluid text-center career-banner">
      <h1 className="main-title" ><b>Our Career</b></h1>
        <h4 className="sub-main-title">
          With Great Skills & Dedication comes Great jobs Opportunity.
        </h4>
        <h6 className="sub-title">
          At BmarketKing we create a positive working vibe and strong team bonding. Let's Grow and explore together to achieve much more in the services we are providing to our clients.
        </h6>
      </div>
      <Container>
        <div className="g-4 career-cards">
          <Card className="text-center rounded career-card">
            <Card.Body className="py-5 bg-primary text-white">
              <Card.Title>Digital Marketing Executive</Card.Title>
            </Card.Body>
            <ListGroup className="list-group-flush">
                <ListGroupItem>Full Time</ListGroupItem>
                <ListGroupItem>0-1 year experience</ListGroupItem>
                <ListGroupItem>Location palghar</ListGroupItem>
                <ListGroupItem>Send your CV at:- piyush@bmarketking.in</ListGroupItem>
              </ListGroup>
            <Card.Footer className="text-dark">
              <Button href = "mailto: piyush@bmarketking.in" variant="primary">
                Send Your CV Here
              </Button>
            </Card.Footer>
          </Card>
          <Card className="text-center rounded career-card">
            <Card.Body className="py-5 bg-primary text-white">
              <Card.Title>Web Designer</Card.Title>
            </Card.Body>
            <ListGroup className="list-group-flush">
                <ListGroupItem>Full Time</ListGroupItem>
                <ListGroupItem>0-1 year experience</ListGroupItem>
                <ListGroupItem>Location palghar</ListGroupItem>
                <ListGroupItem>Send your CV at:- piyush@bmarketking.in</ListGroupItem>
              </ListGroup>
            <Card.Footer className="text-dark">
              <Button href = "mailto: piyush@bmarketking.in" variant="primary">
                Send Your CV Here
              </Button>
            </Card.Footer>
          </Card>
          <Card className="text-center rounded career-card">
            <Card.Body className="py-5 bg-primary text-white">
              <Card.Title>Graphic Designer</Card.Title>
            </Card.Body>
            <ListGroup className="list-group-flush">
                <ListGroupItem>Full Time</ListGroupItem>
                <ListGroupItem>0-1 year experience</ListGroupItem>
                <ListGroupItem>Location palghar</ListGroupItem>
                <ListGroupItem>Send your CV at:- piyush@bmarketking.in</ListGroupItem>
              </ListGroup>
            <Card.Footer className="text-dark">
              <Button href = "mailto: piyush@bmarketking.in" variant="primary">
                Send Your CV Here
              </Button>
            </Card.Footer>
          </Card>
        </div>
      </Container>

      <div className="small-banner mt-5">
        <div className="container-fluid p-5">
          <h2>
            What we Offer 
          </h2>
          <div className="row">
            <div className="col-md-9 mt-2">
              <h4> 
                Our Expertise & Services with knowledgeable
                courses
              </h4>
            </div>
            <div className="col-md-3 mt-2">
              <Button
                href="tel:9307521084"
                variant="default"
                style={{ color: "white", border: "solid" }}
              >
                Schedule a Call with us
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Career;
