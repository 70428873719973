import React from "react";
import {
  Card,
  Button,
  CardGroup,
  Container
} from "react-bootstrap";
import headinglinedec from "../../assets/images/heading-line-dec.png";
import aboutrightdec from "../../assets/images/about-us-section.png";
import MetaTags from "react-meta-tags";


function About() {
  return (
    <div>
      <MetaTags>
	      <meta charSet="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
        <title>Best digital marketing company & Share Market classes in Palghar</title>
        <meta name="keywords" content="best digital marketing company in palghar, website designing company in palghar, web development company in palghar, BMarketKing, share market classes in palghar"/>
        <meta name="description" content="BMarketKing is the best digital marketing company, website designing company, web development company with share market classes in Palghar"/>
	      <link rel="canonical" href="https://bmarketking.in/about-us"/>
      </MetaTags>
      <div className="container-fluid text-center about-us-banner">
        <h1 className="main-title" ><b>About Us</b></h1>
        <h4 className="sub-main-title">
          Best Digital Marketing Company in Palghar {"&"} Share Market classes
        </h4>
        <h6 className="sub-title">
          BMarketKing is the best digital marketing company, website designing company, web development company with share market classes in Palghar
        </h6>
      </div>
      <Container className="about-us-card">
        <CardGroup>
          <Card className="text-center rounded home-cards-service">
            <Card.Body className="py-5">
              <Card.Title className="service-title"><strong>Our Mission</strong></Card.Title>
              <img
                src={headinglinedec}
                className="img-fluid text-center"
                alt=""
              />
              <Card.Text className="py-5">
              Our mission is to become best digital marketing company in palghar district. An agency that provides online marketing, as well as knowledge to the students who want to explore IT Field and Become financial experts through our share market classes in Palghar (Online/Offline.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button href="/services" variant="primary">Learn More</Button>
            </Card.Footer>
          </Card>
          <Card className="text-center rounded home-cards-service">
            <Card.Body className="py-5">
              <Card.Title className="service-title"><strong>Our Vision</strong></Card.Title>
              <img
                src={headinglinedec}
                className="img-fluid text-center"
                alt=""
              />
              <Card.Text className="py-5">
              We believe in creating a Transparent workflow in between BMarketKing and the Client we are serving. So that their Product becomes a Brand. Through our Courses system, we are going to create Skilled people who can perform any task and bring the best output for their company.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button href="/courses" variant="primary">Learn More</Button>
            </Card.Footer>
          </Card>
          <Card className="text-center rounded home-cards-service">
            <Card.Body className="py-5">
              <Card.Title className="service-title"><strong>Connect With Us</strong></Card.Title>
              <img
                src={headinglinedec}
                className="img-fluid text-center"
                alt=""
              />
              <Card.Text className="py-5">
              Wanted to create your own online presence?
              then connect with us because we are the full-fledge website designing company in Palghar.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button href="/contact-us" variant="primary">Learn More</Button>
            </Card.Footer>
          </Card>
        </CardGroup>
      </Container>

      <div id="about" className="about-us section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="section-heading">
                <h4>
                  What We Do &amp; Who We Are
                </h4>
                <img src={headinglinedec} alt="" />
                <p>
                We provide your business an effective Digital Marketing strategies. By understanding your business, we create and make the best planning so that it can grow in an exponential way.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4>
                      <a
                        href="/"
                        className="btn btn-primary rounded-pill w-100"
                      >
                        Competitor Research
                      </a>
                    </h4>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4>
                      <a
                        href="/"
                        className="btn btn-primary rounded-pill w-100"
                      >
                        Relevant Targeting
                      </a>
                    </h4>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4>
                      <a
                        href="/"
                        className="btn btn-primary rounded-pill w-100"
                      >
                        Robust Strategy
                      </a>
                    </h4>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4>
                      <a
                        href="/"
                        className="btn btn-primary rounded-pill w-100"
                      >
                        Full Trained Knowledge
                      </a>
                    </h4>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4>
                      <a
                        href="/"
                        className="btn btn-primary rounded-pill w-100"
                      >
                        Concept to make Reality
                      </a>
                    </h4>
                  </div>
                </div>
                <div className="col-lg-12"></div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right-image">
                <img src={aboutrightdec} className="about-us-desc" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="small-banner mt-5">
        <div className="container-fluid p-5">
          <h2>
            What we Offer 
          </h2>
          <div className="row">
            <div className="col-md-9 mt-2">
              <h4> 
                Our Expertise & Services with knowledgeable
                courses
              </h4>
            </div>
            <div className="col-md-3 mt-2">
              <Button
                href="tel:9307521084"
                variant="default"
                style={{ color: "white", border: "solid" }}
              >
                Schedule a Call with us
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
