import React from "react";
import ErrorImage  from "../assets/images/error.jpg";
function Error() {
  return (
    <div className="error">
        <br />
        <br />
      <h1 className="display-1">Page Not Found</h1>
      <div>
        <img
          class="que-mark"
          src={ErrorImage}
          alt="Error"
        />
      </div>
      <p>
        The link you have clicked does not exist. Please refresh the page and
        try again.
      </p>
      <a href="/" className="btn btn-primary">Back to Home</a>
    </div>
  );
}

export default Error;
