export let services = [
  {
    metaTags: [
      {
        name: "keywords",
        content:
          "digital marketing agency, best digital marketing agency in palghar, digital marketing service provider, digital marketing agency near me, BMarketKing",
      },
      {
        name: "description",
        content:
          "Enable your business on all online platforms with BMarketKing Digital marketing agency in Palghar, Which is a leading digital marketing service provider.",
      },
    ],
    id: "digital-marketing",
    title: "Best Relevant Digital Marketing Agency in Palghar | BMarketKing",
    className: "digital-marketing-banner",
    heading: "Digital Marketing",
    subHeading:
      "Highly Trusted Best Digital Marketing Agency in Palghar - Lifting Business to a New Height",
    serviceInformation:
      "BMarketKing is a Digital Marketing service provider, who escalates Client Business in a Relevant Way. We are the only Digital Marketing Agency in Palghar that Adapted modern trends sticking to the Updated standardization and bringing the best Outcome from every step we take.",
    cards: [
      {
        title: "SEO",
        info: "Search engine optimization is the most important part for growing your business organically. If you wanted to show your page on “Digital Marketing Agency near me” keyword, it is mandatory you should enable it by SEO",
        learn_more: true,
        call_us: false,
      },
      {
        title: "Social Media Marketing",
        info: "All your Social media platforms are optimized with the relevant information. Creating the best content for your product & running Facebook Ad Campaigns with robust strategies to bring ROI & Leads",
        learn_more: true,
        call_us: false,
      },
      {
        title: "Email Marketing",
        info: "These services are created in such a way that they are targeted to a specific audience to generate Leads.it is one of the best effective mediums of marketing",
        learn_more: true,
        call_us: false,
      },
      {
        title: "Start conversation",
        info: "Get in touch to explore more about Digital Marketing Agency.",
        learn_more: false,
        call_us: true,
      },
    ],
  },
  {
    metaTags: [
      {
        name: "keywords",
        content:
          "affordable website design company, website designing company, modern website design, website designing agency, BMarketKing",
      },
      {
        name: "description",
        content:
          "Modern website design agency with the offering of best dynamic Affordable website design company that helps to enhance the user experience",
      },
    ],
    id: "web-design",
    title: "Affordable website design company | BMarketKing | Palghar",
    className: "web-design-banner",
    heading: "Website Design",
    subHeading:
      "Own it now your Online Presence with our Affordable Website Design Company",
    serviceInformation:
      "BMarketKing is a Website Designing Company who will empower your Business Digitally. Our team has talented designers who have mastery in creating Best User Experience with Modern Website Design with impactful USP which makes us the only high-quality business generating website designing agency for our client.",
    cards: [
      {
        title: "Website Designing",
        info: "We create the best UX because we understand our client's product and targeting to the relevant audience through SEO. we make a pocket-friendly website that is responsive. We are proud to say that we are an Affordable website design company",
        learn_more: true,
        call_us: false,
      },
      {
        title: "Logo Design",
        info: "Before Creating a Modern website Design we Build a Logo that is unique, as well as show your business Identity.",
        learn_more: true,
        call_us: false,
      },
      {
        title: "Dynamic Website Design",
        info: "Team is skilled in making both static & Dynamic designs. Whichever is suited best for your product. BMarketKing team will do consulting about that. Because we believe in transparency",
        learn_more: false,
        call_us: true,
      },
      {
        title: "Build your responsive Website",
        info: "Get in touch to explore more about Website Designing Agency.",
        learn_more: false,
        call_us: true,
      },
    ],
  },
  {
    metaTags: [
      {
        name: "keywords",
        content:
          "web development company, best web development company, web development agency, website developers in palghar, BMarketKing",
      },
      {
        name: "description",
        content:
          "Best web development company offers robust website developers in palghar. BMarketKing  web development agency make your vision into reality",
      },
    ],
    id: "web-development",
    title: "Best Web development company | BMarketKing",
    className: "web-development-banner",
    heading: "Web Development",
    subHeading:
      "Development is now Rewarding - Get Performance & Agility with Best Web Development Company",
    serviceInformation:
      "At BMarketKing our team work in sync to develop Flexible dynamic websites & Softwares. This brings all the potential together, as one can store all the data, connect calls, send Mails, manage Appointments, and much more. We are the one and only Web development agency that focused on increasing customer retention and Engagement.",
    cards: [],
  },
  {
    metaTags: [
      {
        name: "keywords",
        content:
          "video production services, business video production services, promotional video production, video production company, video production companies near me",
      },
      {
        name: "description",
        content:
          "we provide creative & eye-catching promotional business video production services. we give life to the content through our video production company.",
      },
    ],
    id: "video-graphic",
    title: "Innovative business video production services in Palghar",
    className: "video-graphic-banner",
    heading: "Video Production",
    subHeading:
      "You are this Close to getting Pocket-friendly Video Production Services for your Businesses",
    serviceInformation:
      "Need Help promoting your business & product? At BMarketKing we help you to discover your product potential through our video production services. We provide the best business video production services by creating unique and customer-engaging videos.",
    cards: [
      {
        title: "Business Video",
        info: "Using modern VFX Software. Our team creates appealing videos that boost the business growth x5 and reach the right audience. Visuals that are made are totally on client needs with the essence of our Guidance.",
        learn_more: true,
        call_us: false,
      },
      {
        title: "Promotional Video",
        info: "Promotional video production is that genre in which we make likable high-quality Advertisement videos to publish on all online Platforms for generating Leads.",
        learn_more: true,
        call_us: false,
      },
      {
        title: "Influencing Video",
        info: "Digital video is one of the best conquering platforms through presenting yourself as a Brand and sending your relevant views to the inspiring targeted Audience.",
        learn_more: true,
        call_us: false,
      },
    ],
  },
  {
    metaTags: [
      {
        name: "keywords",
        content:
          "content marketing agency, content writing agencies, best content marketing agency, blog writing services, content marketing companies",
      },
      {
        name: "description",
        content:
          "BMarketKing is the best content marketing agency. Offers creative content creation to your business for conversion. unique content writing & Blog writing services are achieved",
      },
    ],
    id: "content-marketing",
    title: "Engaging Content marketing & writing Agency | Blog writing services",
    className: "content-marketing-banner",
    heading: "Content Marketing",
    subHeading:
      "Balance your Design with Lead Engaging fully of Words - Best Content Marketing Agency",
    serviceInformation:
      "Content writing is the arrangement of relevant and inspiring words put together to form an impactful sentence or informative Blog, newsletter, Emails, website content and much more. All these hustles are to provide High-quality  Targeted Audience to the brand for long-term Winning.",
    cards: [
      {
        title: "Website Content services",
        info: "At BMarketKing we believe in Blending Design with the Content. To hold users for a longer time on the website and generate Best OutCome from it.",
        learn_more: true,
        call_us: false,
      },
      {
        title: "Blog Writing services",
        info: "We are not just a Blog Content writing agencies. We Make SEO-optimized Blogs that are eye-catching, have Proper information targeting, and generate High Website Traffic.",
        learn_more: true,
        call_us: false,
      },
      {
        title: "Newsletter & Email Services",
        info: "At BMarketKing we make Newsletters and email content that are lead-generating through the Call to Action Button. Selecting the right Audience to brand your products or services.",
        learn_more: true,
        call_us: false,
      },
    ],
  },
];

export default services;
