import React from 'react'
import logo from "../../assets/images/bmarketkingorg.png";
import { Navbar,Container,NavDropdown, Nav } from 'react-bootstrap';

function header() {
  return (
    <Navbar className="nav-top" bg="light" collapseOnSelect expand="lg" fixed="top" style={{"fontWeight": 600}}>
  <Container>
  <Navbar.Brand href="/"><img src={logo} className="nav-logo" alt="logo"/></Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
    <Nav className="ml-auto">
      <Nav.Link href="/">Home</Nav.Link>
      <Nav.Link href="/about-us">About Us</Nav.Link>
      <NavDropdown title="Our Services" id="collasible-nav-dropdown">
      <NavDropdown.Item href="/services">All Services</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="/services/digital-marketing">Digital Marketing</NavDropdown.Item>
        <NavDropdown.Item href="/services/web-design">Web Design</NavDropdown.Item>
        <NavDropdown.Item href="/services/web-development">Web Development</NavDropdown.Item>
        <NavDropdown.Item href="/services/video-graphic">Video Graphic</NavDropdown.Item>
        <NavDropdown.Item href="/services/content-marketing">Content Marketing</NavDropdown.Item>
        {/* <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
      </NavDropdown>
      <NavDropdown title="Our Courses" id="collasible-nav-dropdown">
      <NavDropdown.Item href="/courses">All Courses</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="/courses/stock-market-course">Stock Market</NavDropdown.Item>
        <NavDropdown.Item href="/courses/digital-marketing-course">Digital Marketing</NavDropdown.Item>
        <NavDropdown.Item href="/courses/web-design-course">Web Design</NavDropdown.Item>
        <NavDropdown.Item href="/courses/graphic-design-course">Graphic Designing</NavDropdown.Item>
        {/* <NavDropdown.Divider /> */}
        {/* <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
      </NavDropdown>
      <Nav.Link href="/blogs">Blogs</Nav.Link>
      {/* <Nav.Link href="/social">Stay Connected</Nav.Link> */}
      <Nav.Link href="/career">Career</Nav.Link>
      <Nav.Link href="/contact-us">Contact Us</Nav.Link>
    </Nav>
    {/* <Nav>
      <Nav.Link href="#deets">More deets</Nav.Link>
      <Nav.Link eventKey={2} href="#memes">
        Dank memes
      </Nav.Link>
    </Nav> */}
  </Navbar.Collapse>
  </Container>
</Navbar>
  )
}

export default header
