export let courses = [
  {
    metaTags: [
      {
        name: "keywords",
        content:
          "digital marketing course, best digital marketing course, digital marketing courses in palghar, digital marketing classes in palghar, BMarketKing",
      },
      {
        name: "description",
        content:
          "Searching for the best digital marketing course ? We are one call away from you. Both online & classroom training is given to improve your skills.",
      },
    ],
    id: "digital-marketing-course",
    title: "Best Digital Marketing Course in Palghar | BMarketKing",
    heading: "Digital Marketing Course",
    className: "digital-marketing-banner",
    subHeading:
      "To be Expert Learn from Experts - Best Digital Marketing Courses in Palghar",
    courseInformation:
      "BMarketKing provides Training of Digital Marketing Course to Job Seekers, Freelancers, Businessman, College Students and to the Future Entrepreneurs to drive their Startup in a smooth way.",
    cards: [],
  },
  {
    metaTags: [
      {
        name: "keywords",
        content:
          "website design course, website design course near me, website designing classes in palghar, best web design courses, BMarketKing",
      },
      {
        name: "description",
        content:
          "Enroll for Website Design Course In Palghar to develop your web design skills with Hands-on practical classroom training sessions only in BMarketKing.",
      },
    ],
    id: "web-design-course",
    title: "Enroll for best web design courses | BMarketKing",
    heading: "Web Design Course",
    className: "web-design-banner",
    subHeading: "Ultimate Structured Website Design Course",
    courseInformation:
      "Looking for a career in Designing BMarketKing is the Right Choice for Website Designing Classes in Palghar. Our Website Design course will mold you from beginner to Expert. It will make you specialize in creating a professional design-based as well as Fully Performance Seo Optimized Website.",
    cards: [],
  },
  {
    metaTags: [
      {
        name: "keywords",
        content:
          "best graphic design courses, graphic design classes, graphics design courses in palghar, graphic design classes near me, BMarketKing",
      },
      {
        name: "description",
        content:
          "Innovate your idea into living pixels. Learn Graphic design courses with BMarketKing in Palghar. Join Graphic Design classes in Palghar to learn from Industry Expert",
      },
    ],
    id: "graphic-design-course",
    title: "Best Graphic Design courses & classes to get Expert",
    heading: "Graphic Design Course",
    className: "video-graphic-banner",

    subHeading:
      "Touch Up Your Illustrated Power With Best Graphic Design Courses",
    courseInformation:
      "Graphic Designing is a Craft of sending your message to the right audience. Get skilled to create a creative image on the latest Software so in the future your craftsmanship help many products to build brands image. BMarketKing is the Best graphics design courses in palghar",
    cards: [],
  },
  {
    metaTags: [
      {
        name: "keywords",
        content:
          "stock market courses, stock market classes, stock trading courses, share market basics for beginners, share market classes near me",
      },
      {
        name: "description",
        content:
          "To be a Bull or Bear you must have their Brains. BMarketKing offers Stock Market Courses, Offline in Palghar as well as Online all around the World. Enroll Now",
      },
    ],
    id: "stock-market-course",
    title: "Hold Stock Market Courses & Classes in Palghar | BMarketKing",
    heading: "Stock Market Courses",
    className: "stock-marketing-banner",
    subHeading:
      "India Loves Buying & Selling - Learn Stock Market Courses online & Offline",
    courseInformation:
      "BMarketKing assists you to learn and gain knowledge through Stock Market Classes. We help to understand the Fundamentals, teach you how to read charts so you become technically strong. Our Stock Trading Courses Builds your psychology for Trading and Investments. Our Stock Market Courses is all about getting a proper Mindset so one can sustain for a Longer Period of Time. BMarketKing Stock Market Classes are Conducted Online as well as Offline.",
    cards: [
      {
        title: "Basic",
        info: "Share market basics for beginners to learn the starting phases of the investing market. Understanding different types of platforms, tools, and indicators.",
        learn_more: true,
        call_us: false,
      },
      {
        title: "Advance",
        info: "In this Plan you will Explore more about how to become a Trader with a Proper Mindset, Risk Management techniques, and much more.",
        learn_more: true,
        call_us: false,
      },
      {
        title: "Pro",
        info: "Trade or Invest like a Pro. By making a robust base of basic and Advance knowledge. Learn to make Strategies to bring out the best outcome. The market is like an Ocean Lets learn to Swim in it.",
        learn_more: true,
        call_us: false,
      },
    ],
  },
];

export default courses;
