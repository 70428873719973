import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/home/Home";
import About from "./pages/aboutus/About";
import Services from "./pages/services/Services";
import SingleService from "./pages/services/SingleService";
import Courses from "./pages/courses/Courses";
import DigitalMarketing from "./pages/courses/DigitalMarketing";
import SingleCourses from "./pages/courses/SingleCourses";
import Career from "./pages/careers/Career";
import Header from "./pages/header/Header";
import Footer from "./pages/footer/Footer";
import Error from "./components/Error";
import Contact from "./pages/contact/Contact";

import "./assets/css/index.css";

function App() {
  return (
    <div className="App">
      <Router basename={"/"}>
        <Header />
        <br />
        <br />
        <br />

        <Switch>
          <Route exact path={`/`} component={Home}>
            <Home />
          </Route>

          <Route exact path={`/home`} component={Home}>
            <Home />
          </Route>

          <Route exact path={"/about-us"} component={About}>
            <About />
          </Route>

          <Route exact path={"/services/:id"} component={SingleService}>
            <br />
            <SingleService />
          </Route>

          <Route exact path="/services" component={Services}>
            <br />
            <Services />
          </Route>

          <Route exact path={"/courses/digital-marketing-course"} component={DigitalMarketing}>
            <br />
            <DigitalMarketing />
          </Route>

          <Route exact path={"/courses/:id"} component={SingleCourses}>
            <br />
            <SingleCourses />
          </Route>

          <Route exact path={"/courses"} component={Courses}>
            <br />
            <Courses />
          </Route>

          <Route exact path={"/career"} component={Career}>
            <Career />
          </Route>

          <Route exact path={"/contact-us"} component={Contact}>
            <Contact />
          </Route>

          <Route component={Error}>
            <Error />
          </Route>
        </Switch>
        <br />
        <br />
        <br />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
