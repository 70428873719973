import React from "react";
import {
  Button,
  Container,
  Row,
  Col
} from "react-bootstrap";
import rightImg from "../../assets/images/banner-courses.png";
import MetaTags from "react-meta-tags";

// import aboutrightdec from "../../assets/images/about-right-dec.png";
// import { MdLibraryBooks } from "react-icons/md";

function Courses() {
  return (
    <div className="about-banner">
      <MetaTags>
        <meta charSet="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
        <title>Finest Digital Marketing Institute | stock market courses online</title>
        <meta name="keywords" content="digital marketing institute, stock market courses online, web design course, graphics design course, job oriented courses"/>
        <meta name="description" content="BMarketKing offers a wide range of Digital Marketing Institute, stock market courses online. job oriented courses like web design course & graphics design course."/>
        <link rel="canonical" href="https://bmarketking.in/courses"/>
      </MetaTags>

      <div className="container-fluid text-center courses-banner">
        <h1 className="main-title" ><b>Our Courses</b></h1>
        <h4 className="sub-main-title">
          Advanced Digital Marketing Institute {"&"} Pro Stock Market Courses Online
        </h4>
        <h6 className="sub-title">
          BMarketking is putting forward there Best Online & Offline courses. We are providing Digital Marketing Institute, Stock Market Courses online & offline according to batches, Web design Course, Graphics Design course and much more in coming years.
        </h6>
      </div>

      <Container className="text-center course-section">
        <Row className="custom-card-container">
          <Col lg={6}>
            <Container>
              <h1 className="display-4 mb-4"><a href="/courses/stock-market-course">Check out our available Courses</a></h1>
              <Row>
                <Col sm={12} lg={6} className="mb-1 ml-1"><a href="/courses" className="btn btn-primary rounded-pill">Book your Demo Lecture</a></Col>
                <Col sm={12} lg={6} className="mb-1 ml-1"><a href="tel:9307521084" className="btn btn-primary rounded-pill" >Enquiry Now:- 9307521084</a></Col>
              </Row>
            </Container>
          </Col>
          <Col className="mt-4" lg={6}>
            <img src={rightImg} className="right-img img-fluid" alt="" width="500px"/>
          </Col>
        </Row>
      </Container>

      <div className="small-banner mt-5">
        <div className="container-fluid p-5">
          <h2>
            What we Offer 
          </h2>
          <div className="row">
            <div className="col-md-9 mt-2">
              <h4> 
                Our Expertise & Services with knowledgeable
                courses
              </h4>
            </div>
            <div className="col-md-3 mt-2">
              <Button
                href="tel:9307521084"
                variant="default"
                style={{ color: "white", border: "solid" }}
              >
                Schedule a Call with us
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Courses;
