import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import Favicon from 'react-favicon'
import fic from './assets/images/bmarketkingorg.png'

ReactDOM.render(
  <React.StrictMode>
    <Favicon url={fic} />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);