import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import services from "../../data/services.js";
import { Container, Card } from "react-bootstrap";
import MetaTags from "react-meta-tags";
import img from "../../assets/images/underconstruction.svg";

function SingleService() {
  const [service, setService] = useState();
  let { id } = useParams();

  useEffect(() => {
    let filteredService = services.find((item) => item.id === id);
    setService(filteredService);
  }, [id]);

  if (service) {
    return (
      <div>
        <MetaTags>
          <meta charSet="UTF-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          ></meta>
          <title>{service.title}</title>
          {service.metaTags.map((tag) => {
            return <meta name={tag.name} content={tag.content} />;
          })}
          <link
            rel="canonical"
            href={`https://bmarketking.in/services/` + id}
          ></link>
        </MetaTags>

        <div className={"container-fluid text-center " + service.className}>
          <h1 className="main-title">
            <b>{service.heading}</b>
          </h1>
          <h4 className="sub-main-title">{service.subHeading}</h4>
          <p
            className="sub-title"
            dangerouslySetInnerHTML={{
              __html: service.serviceInformation.split(".").join(""),
            }}
          ></p>
        </div>

        <div className="container custom-card-container">
          {service.cards.map((card, index) => {
            return (
              <Card className="text-center rounded dinamic-card">
                <Card.Body className="py-5 ">
                  <Card.Title className="fs-5 service-title">
                    <strong>{card.title}</strong>
                  </Card.Title>
                  <Card.Text className="py-5 ">{card.info}</Card.Text>
                </Card.Body>
                <Card.Footer>
                  {card.call_us ? (
                    <a href="tel:9307521084" className="btn btn-primary">
                      Schedule call
                    </a>
                  ) : (
                    ""
                  )}
                </Card.Footer>
              </Card>
            );
          })}
          {!service.cards.length && (
            <Container className="text-center">
              <img src={img} className="img-fluid w-50" alt="" />
              <h1 className="display-1">Coming Soon</h1>
              <hr />
              <a className="btn btn-primary rounded-pill me-4 mt-4" href="/services">
                Check our available services and courses
              </a>
              <a
                className="btn btn-primary rounded-pill me-4 mt-4"
                href="tel:9307521084"
              >
                Schedule a call{" "}
              </a>
              <a className="btn btn-primary rounded-pill me-4 mt-4" href="/career">
                Looking for Teammates in Palghar
              </a>
            </Container>
          )}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default SingleService;
